.sphere {
    position: absolute;
    top: -50px;
    left: -80px;
    width: 200px;
    height: 200px;
    background: radial-gradient(69.90% 72.36% at 19.21% 66.34%, #1E1E1E 0%, #C2C2C2 100%);
    box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.25);
    border-radius: 396px;
    color: var(--primary-color);
    transition: all 200ms;
}

/* Tablet Sizing */
@media only screen and (min-width: 480px) {
    .sphere {
        top: -50px;
        left: -100px;
        width: 250px;
        height: 250px;
    }
}

/* Small Screen Sizing */

@media only screen and (min-width: 768px) {
    .sphere {
        top: -120px;
        left: -180px;
        width: 400px;
        height: 400px;
    }
}

@media only screen and (min-width: 1024px) {
    .sphere {
        top: -120px;
        left: -180px;
        width: 450px;
        height: 450px;
    }
}