@import "../../../global.scss";

.modal-container {
    padding: 1rem;
    border: 3px solid $secondary-green;
    min-width: 500px;
    z-index: 10;
    background-color: white;
    position: fixed;
    width: 50vw;
    margin: auto;
    top: 25%;
    left: 30%;
    transition: 0.5s;
}

.modal-header {
    border-bottom: 1px solid black;
    width: 100%;
}

.modal-header-text {
    font-size: 1.5rem;
    text-align: center !important;
}

.modal-header-cancel button i {
    font-size: 1.5rem;
    color: red;
    padding: 0.1rem;
    transition: color 200ms;
    &:hover {
        color: rgb(247, 108, 108);
    }
}