//@import url("../../../global");


$primary-color: #0076c6;
$mobile-max-width: 480px;
$tablet-max-width: 768px;
$small-screen-width: 1024px;
$desktop-screen-width: 1200px;

.generic-sia-table{
    overflow: auto;
    max-height: 70vh;
}