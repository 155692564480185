.create-ips-full-container {
    padding: 1rem;


    .ips-container-header {
        font-size: 2rem;
    }
    .ips-container-subheader {
        font-size: 1.4rem;
        margin-bottom: 1rem;    
    }
}

.ips-section-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ips-section-header {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }
    
}


.ips-section-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;

    &-header {
        margin: 1rem 0;
        text-align: center;
        font-size: 1.2rem;
    }

    .input-section {
        margin: 1rem;
        border-bottom: 1px solid black;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .radio-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem;

        input {
            margin: 0 1rem;    
        }
    }

    textarea {
        width: 95%;
        height: 20vh;
        padding: 1rem;
    }

    input {
        padding: 0.25rem;
        font-size: 1.1rem;
        margin-right: 2rem;
        margin-bottom: 1rem;
    }

    input[type="radio"] {
        margin-right: 0;
        text-align: center;
    }

    .ips-objective-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        border-bottom: 1px solid black;
    }
   
}


.account-fee-description-form {
    margin: 1rem;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    border: 2px solid #ccc;
    

    .form-header {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    label {
        min-width: 12rem;
        font-weight: bold;
    }

    input {
        border-radius: 10rem;
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        box-shadow: 2px 2px 2px 2px #ccc;
    }
}

.account-fee-descriptions {
    box-shadow: 2px 2px 2px 2px #ccc;
    width: 50%;
    padding: 1rem;
    margin-bottom: 2rem;

    .account-fee-description {
        border-bottom: 1px solid #ccc;
        //border: 2px solid red;

        .account-fee-description-header-container {
            display: flex;
            justify-content: space-between;
        }

        .account-fee-description-header {
            font-size:1.1rem;
            font-weight: bold;
        }
    }
}

.review-details-section {
    width: 60%;
    margin-bottom: 1rem;;
    border-bottom: 1px solid #ccc;

    &.section-row {
        display: flex;
        justify-content: space-between;
    }

    .label {
        font-weight: bold;
        margin-right: 1rem;
    }

    .value {
        margin-bottom: 0.5rem;
    }

    .account-descriptions-review{
        display: flex;
        .account-description-section {
            margin: 0.5rem 1rem;
        }
    }
}

