@import '../../../../global.scss';

.regulatory-list-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
        min-width: 150px !important;
    }

    select {
        max-width: 200px !important;
    }
}

.regulatory-list-section-header {
    background-color: $periwinkle; 
    padding-left: 1rem; 
    padding: 0.5rem 1rem;
    font-weight: bold;
    font-size: 1rem;

}

.add-regulatory-detail-btn-cont {
    margin: 0.5rem;
    

    button {
        width: 20%;
        padding: 0.5rem;
        background-color: $gunmetal;
        color: white;
        border: none;
        border-radius: 1rem;
        transition: background-color 0.25s;
        &:hover {
            background-color: $electric-indigo;

        }
    }
}

.breakline {
    height: 1px;
    background-color: black;

}