.main-container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.main-container-header {
    font-size: 3rem;
    text-align: center;
}







