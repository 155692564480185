@import '../../../global.scss';

.form-progress-bar {
    display: flex;
    align-items: center;
    width: 100%;
    //background: rgba(255, 254, 254, 0.815);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    color: black;

    @media(min-width: $small-screen-width){
        padding: 0.5rem 5rem;
    }

    .form-progress-bar-step{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 12rem;

        .form-progress-bar-step-number{
            font-size: 1.3rem;
            font-weight: bold;
            color: white;
            background-color: #00A3FF;
            padding: 0.5rem;
            border-radius: 10rem;
            width: 3rem;
            text-align: center;
        
            &.complete {
                background-color: #10dd88;
            }
        }

        .form-progress-bar-step-label {
            margin-top: 0.5rem;
            //font-weight: bold;
            font-size: 1.15rem;
            text-align: center;
        }

        &.active {

            .form-progress-bar-step-number{
                background-color: #da9100;
            }

            .form-progress-bar-step-label {
                font-weight: bold;
            }
        }
    }
    

    .form-progress-bar-outerline {
        width: 100%;
        height: 0.5rem;
        background: rgb(126, 126, 126);
        margin: 0 1rem;
        //border: 0.5px solid #00A3FF;

        .form-progress-bar-innerline{
            width: 0%;
            height: 100%;
            background: #00A3FF;
            transition: width 0.5s ease-in-out;
        }
    }
}

.form-progress-container{
    padding: 1rem;

    .form-progress-btns-container {
        display: flex;
        justify-content: space-between;
        padding: 1rem 10rem;

        .form-progress-btn-container{

            button {
                padding: 0.25rem 1.25rem;
                font-family: "Source Sans";
                border: none;
                background-color: $electric-indigo;
                color: white;
                font-size: 1.2rem;
                transition: 0.3s all ease-in-out;
                &:hover {
                    background-color: $periwinkle;
                    color: black;
                }

                &:disabled {
                    background-color: rgb(190, 190, 190) ;
                    //color: black;

                    &:hover {
                        cursor: not-allowed;
                        color: white;
                    }
                }
            }
        }
    }
}