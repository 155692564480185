/* Mobile First Values */

.main-header-container {
    margin: 1rem;
    width: 70%;
}

.main-header {
    font-size: 2.5rem;
    text-align: center;
    background: -webkit-linear-gradient(#000000 0%, #CDAD00 80%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
}

.sub-header {
    font-family: 'Source Sans';
    font-size: 1.25rem;
    text-align: center;
}

/* Small Screen Sizing */
@media only screen and (min-width: 480px) {

    .main-header-container {
        margin: 1rem;
        width: 100%;
        text-align: center;
    }

    .main-header {
        font-size: 3rem;
    }

    .sub-header {
        font-size: 1.5rem;
    }
}

/* Small Screen Sizing */
@media only screen and (min-width: 768px) {
    
    .main-header-container {
        width: 60%;
    }
    
    .main-header {
        font-size: 4rem;
        
    }

}


/* Small Screen Sizing */
@media only screen and (min-width: 1025px) {
    .main-header-container {
        width: 30%;
    }

    .main-header {
        font-size: 5rem;
        text-align: left;
    }

    .sub-header{
        text-align: left;
    }
}
