@import '../../../global.scss';

.main-participant-detail-page-container {
    //background: white;
    width: 95%;
    margin: 1rem;
    min-height: 90vh;

    .page-header {
        padding: 1rem;    
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background: white;
        box-shadow: 2px 4px 2px 2px #ccc;
        margin-bottom: 1rem;

        .left-side-header{
            margin-left: 0.25rem;
            .page-header-primary-text {
                font-family: "Roboto Condensed Bold";
                color: $primary-blue;
                font-size: 3rem;
            }

            .page-header-secondary-text {
                font-family: "Roboto Condensed Bold";
                color: $primary-grey;
                font-size: 1.25rem;
            }

            .page-header-rtn-container {
                button {
                    background-color: transparent;
                    border: none;
                }
            }
        }

        .right-side-header {
            .participant-roles-container {
                padding: 0.25rem;
                margin-right: 2rem;

                .container-title {
                    font-size: 1.2rem;
                    font-family: "Roboto Condensed Bold";
                }
            }

            .participant-roles-container{
                margin-top: 1rem;
            }
        }
    }

    .participant-page-tabs{
        display: flex;
        justify-content: center;
        border-bottom: 2px solid white;

        .participant-page-tab {
            margin: 0rem 1rem;
            margin-top: 2rem;

            button {
                border: none;
                padding: 0.25rem 3rem;
                font-family: "Roboto Condensed Bold";
                background-color: #FFF;
                font-size: 1.25rem;
                transition: all 200ms;
                border-radius: 15px 15px 0px 0px;
            }

            &.active, &:hover {
                button {
                    background-color: $primary-blue;
                    color: white;
                }
            }
        }
    }
    
    .page-content {
        background: white;
        padding: 1rem; 
        display: flex;
        box-shadow: 2px 4px 2px 2px #ccc;
    }

    .hr {
        border: 0.5px solid #ccc;
        margin: 0 1rem;
    }

    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }

    .participant-page-details {
        //display: flex;
        //border: 2px solid red;
        //padding: 0.5rem;
        width: 90%;
        min-height: 40vh;
        justify-content: center;
        margin: 2rem auto;
        margin-top: 2rem;
        box-shadow: 2px 2px 2px 2px #ccc;
        background: #FCFBFB;

        .participant-page-details-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: white !important;
            padding: 1rem;

            .page-header-primary-text{
                width: 100%;
                text-align: center;
                font-size: 1.8rem;
                color: $primary-blue;
                font-family: "Roboto Condensed Bold";


                @media (min-width: $small-screen-min-width){
                    width: 25%;
                    text-align: left;
                }
            }

            .participant-page-details-header-tabs {
                display: flex;
                justify-content: center;
                width: 100%;

                @media (min-width: $small-screen-min-width){
                    width: 50%;
                }

                .participant-page-details-header-tab {
                    margin: 1rem;
                    width: 32%;
                
                    button {
                        min-width: 100%;
                        padding: 0.25rem 1.5rem;
                        font-family: "Roboto Condensed Bold";
                        border: none;
                        border-radius: 0.5rem;
                        transition: all 200ms;
                    }

                    &.active, &:hover {
                        button{
                            background-color: $primary-blue;
                            color: white;
                        }
                    }
                }
            }

            .page-header-action-container {
                display: flex;
                justify-content: flex-end;
                width: 100%;

                @media (min-width: $small-screen-min-width){
                    width: 25%;
                    text-align: left;
                }
            }
            @media (min-width: $small-screen-min-width){
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

        }

        .participant-details-container {
            padding: 1rem;
            //border: 2px solid red;
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;

            .details-section {
                width: 100%;

                .details-section-header {
                    font-family: "Roboto Condensed Bold";
                    font-size: 1.5rem;
                    margin-bottom: 0.5rem;
                    margin-left: 0.5rem;
                }

                .details-container {
                    .detail-container {
                        display: flex;
                        margin: 0.5rem;
                        
                        .detail-label {
                            font-weight: bold;
                            min-width: 140px;
                            font-family: "Roboto Condensed Bold";
                        }

                        .detail-value {
                            font-family: "Cabin";
                        }
                    }

                    .details-table {
                        width: 100%;
                        border-collapse: collapse;
                        border: 1px solid #ccc;

                        th, td {
                            border: 1px solid #ccc;
                            padding: 0.5rem;
                        }

                        th {
                            background-color: $primary-blue;
                            color: white;
                            font-family: "Roboto Condensed Bold";
                        }

                        tr {
                            &:nth-child(even) {
                                background-color: #f2f2f2;
                            }

                            &:hover{
                                background-color: #ddd;
                            }
                        }
                    }
                }                 
                
            }

            .vr {
                border: none;
                height: 0;
                margin: 0;
            }
            

            @media  (min-width: $small-screen-min-width) {
                flex-direction: row;
                justify-content: space-evenly;
                align-items: flex-start;

                .vr {
                    border-left: 1px solid #000000;
                    height: 40vh;
                    margin: 0 1rem;
                }

                .details-section {
                    margin: 1rem 0;
                    width: 30%;
                }
            }
        }
    }
}