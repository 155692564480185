@import '../../../global.scss';

.account-joint-details-card{
    padding: 1rem;
    box-shadow: 4px 1px 6px 0px $electric-indigo;
    width: 90%;


    .account-joint-details-header {
        .header-text {
            font-size: 1.3rem;
        }
    }

    .account-joint-details-body {
        margin-top: 1rem;
        
        select {
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            width: 100%;
        }
    }
}