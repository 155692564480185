@import './global.scss';

.main-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .sia-navbar-container {
        width: 15%;
        height: 90vh;
        position: fixed;
        top: 8vh;
        box-shadow: 4px 2px 4px 0px #ccc;
        background: white;
    }

    .sia-content {
        margin-left: 16%;
        margin-top: 8vh;
        width: 84%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        background: $secondary-grey !important;
    }

    .sia-content-no-navbar {
        margin-top: 8vh;
        height: 85vh;
        width: 100%;
        display: flex;
        background: $secondary-grey !important;
    }

    .sia-footer-container {
        height: 6.8vh;
        width: 100%;
        display: flex;
        background: $secondary-grey;
        border: 1px solid #ccc;
    }
}