.risk-step {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-self: flex-start;
    margin-top: 0.5rem;
}

.risk-step-label {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.5rem;
}

.risk-step-input-container {
    width: 40%;
    display: flex;
    align-items: center;
}

.risk-step-input-container label {
    width: 30%;
    margin: 1rem;
}

.risk-step-input-container select {
    width: 100%;
    margin: 1rem;
}

.risk-section-question-container {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    align-items: center;
}
.risk-section-question {
    padding: 0.2rem;
    margin-bottom: 0.4rem;
    width: 50%;
    text-align: justify;
}

.risk-section-question-header {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.2rem;
}

.risk-section-input-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.risk-section-input-container select {
    width: 50%;
}

.risk-section-table {
    width: 50%;
}
