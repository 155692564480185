@import '../../../global.scss';

.login-container{
    height: 85vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media only screen and (min-width: 1024px) {
    .login-container {
        flex-direction: row;
    }
}

.login-container-new {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10rem;

    .border-wrap-container {
        height: 40%;
        width: 60%;
        padding: 0.25rem;
        background: linear-gradient(to right, $primary-blue, $primary-grey 15%, $secondary-green 100%);
        border-radius: 2.5rem;

        @media (min-width: $tablet-min-width) {
            width: 50%;
            height: 50%;
        }

        @media (min-width: $small-screen-min-width) {
            width: 50%;
            height: 50%;
        }

        @media (min-width: $desktop-screen-min-width) {
            width: 40%;
            height: 50%;
        }
    }

    

    .login-main-content-container {
        background: #FFFEFE;
        width: 100%;
        height: 100%;
        border-radius: 2.5rem;
        
        .login-header-container {
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            

            .login-header {
                font-size: 2.5rem;
                font-family: "Roboto Condensed Bold";
                color: $primary-grey;
            }

            .login-subheader {
                font-size: 1.25rem;
                font-weight: bold;
                font-family: "Roboto Condensed Bold";
                color: $primary-blue;
                margin-top: -0.5rem;
            }
        }



        .login-form-container {
            //border: 2px solid red;
            height: 50%;
            width: 100%;

            &.has-qr-code{
                height: 65%;
            }

            .login-form-header {
                font-size: 1.2rem;
            }
            
            .qr-code-container {
                width: 2000px;
            }

            .login-form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .login-form-input-cont {
                    margin: 1rem;
                    width: 100%; 
                    text-align: center;
                    position: relative;
                    

                    label {
                        position: absolute;
                        top: 0px;
                        padding: 0.5rem 1rem;
                        font-size: 1.1rem;
                        font-family: "Cabin Bold";
                        color: $primary-grey;
                        transition: all 200ms;
                        z-index: 0;
                    }

                    input {
                        width: 80%;
                        border-radius: 1rem;
                        border: 1px solid $secondary-grey;
                        padding: 0.5rem 1rem;
                        z-index: 1;
                    }

                    &.focused, &.filled { 
                        label {
                            transform: translateY(-1.6rem);
                            font-size: 0.85rem;
                        }
                    }
                }

                .login-form-btn-submit-cont {
                    //margin-top: 1rem;
                    
                    button {
                        background: linear-gradient(.25turn, $primary-grey 75%, $secondary-green);
                        border: none;
                        padding: 0.25rem 2rem;
                        border-radius: 2rem;
                        color: white;
                        transition: all 200ms;
                        font-weight: bold;
                        font-size: 1.25rem;
                    
                        &:hover {
                            background-color: $secondary-grey;
                            background: linear-gradient(.25turn, $primary-grey 25%, $secondary-green);
                        }
                    }
                }
            }
        }

        .login-footer {
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-weight: bold;
            font-size: 0.8rem;
            color: $primary-grey;
            margin-top: 1rem;
        }

        .loading-container {
            margin-top: 1rem;
        }
    }
}
