.login-card {
    height: 400px;
    box-shadow: 5px 5px 15px grey;
    border: 2px solid black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border: 0px solid red;
    border-radius: 4rem;
}

.login-card-header {
    font-size: 2.5rem;
    font-family: 'Roboto';
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.login-form-input-container {
    text-align: center;
    margin: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-form-input-container input {
    width: 60%;
    height: 2rem;
    border-radius: 10px;
    outline: none;
    padding: 0.5rem;
}


/* CSS */
.login-form-input-container button {
  --b: 2px;   /* border thickness */
  --s: 0.25rem; /* size of the corner */
  --color: #373B44;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 1s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;
font-size: 1.2rem;
  border: 0;
  font-family: 'Source Sans', sans-serif;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.login-form-input-container button:hover,
.login-form-input-container button:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.login-form-input-container button:active {
  background: var(--color);
  color: #fff;
}

.no-account-link {
    text-decoration: underline;
}

.no-account-link:hover {
    cursor: pointer;
}



/* Tablet Sizing */
@media only screen and (min-width: 480px) {
  .login-card {
    width: 80%;
  }
}

/* Small Screen Sizing */

@media only screen and (min-width: 768px) {
  .login-card {
    width: 60%;
  }
}


@media only screen and (min-width: 1025px) {
  .login-card {
    width: 30%;
  }
}

