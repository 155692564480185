.eft-bank-account-container {
    background: rgb(241, 241, 241);
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 10%);
}

.add-bank-account-to-etf-details-container {
    padding: 0.5rem;

    label {
        font-size: 1.2rem;
    }

    select {
        min-width: 25%;
        padding: 0.5rem;
    }

    button {
        margin-left: 1rem;
        
    }
}