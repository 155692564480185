@import '../../../global.scss';

.create-client-summary {
    padding: 1rem;


    .create-client-summary-submit {
        display: flex;  
        justify-content: center;
        font-size: 1.1rem;
        margin-top: 2rem;

        button {
            background-color: $electric-indigo;
            color: white;
            border-radius: 1rem;
            border: none;
            padding: 0.5rem 2rem;
            transition: all 200ms;

            &:hover {
                cursor: pointer;
                background-color: $periwinkle;
            }
        }
    }
}

.create-client-summary-header {
    font-size: 2.5rem;
    text-align: center;
}

.create-client-summary-section {
    width: 95%;
    height: 65px;
    overflow: hidden;
    margin: 1rem auto;
    padding: 1rem;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.116);
    background-color: rgba(128, 128, 128, 0.055);
    transition: all 500ms ease-in-out;

    &-header {
        
        p {
            font-size: 1.5rem;
            font-weight: bold;
        }

        button {
            margin-left: 1rem;
            background-color: transparent;
            border: none;
        }

        display: flex;
        justify-content: space-between;


        &-underline {
            width: 100%;
            height: 3px;
            background-color: $electric-indigo;
            margin-bottom: 0.5rem;

        }
    }  
    
    &-subheader {
        font-size: 1.15rem;
        margin-bottom: 0.5rem;
        text-decoration: underline;
    }

    .client-detail {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        &-label{
            min-width: 50%;
            font-weight: bold;
        }
    }
}

.multiple-sections-summary {
    display: flex;
    width: 100%;

    div {
        width: 40%;
    }

    .client-detail {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        &-label {
            min-width: 100%;
            font-weight: bold;
        }
    }
}