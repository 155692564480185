@import '../../../global.scss';

.sia-header {
    height: 8vh;
    box-shadow: 2px 2px 2px 0px #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: white;

    .sia-header-brand {
        padding: 1rem;
        margin-left: 1rem;

        img {
            width: 100px;
            height: auto;
        }
    }

    .sia-header-options {
        margin-right: 1rem;
        display: flex;
        align-items: center;

        p {
            margin-right: 0.25rem;
            font-family: "Cabin";
            font-weight: bold;
            font-size: 1.1rem;
            color: $primary-grey;
        }

        button {
            background: none;
            border: none;
            
            i {
                font-size: 1.5rem;
                color: $primary-grey;
                transition: 125ms ease-in-out;

                &:hover {
                    color: $primary-blue;
                }
            }
        }
    }
}

