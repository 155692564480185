@import '../../../global.scss';

.participant-details-container {
    background: white;
    box-shadow: 2px 4px 2px 2px #ccc;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    //min-height: 50vh;

    @media(min-width: $small-screen-min-width){
        flex-direction: row;
    }

    .participant-details-nav-bar {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        //border: 2px solid red;
        //border: 2px solid red;

        @media(min-width: $small-screen-min-width){
            flex-direction: column;
            width: 30%;
        }

        .participant-details-navbar-item {
            margin: 0.5rem 0;
            width: 100%;

            @media(min-width: $tablet-min-width){
                width: 50%;
            } 

            @media(min-width: $small-screen-min-width){
                width: 100%;
            }    

            button {
                background: none; 
                border: none;
                padding: 0.25rem 4rem;
                min-width: 100%;
                font-size: 1.25rem;
                border-radius: 0px 15px;
                transition: all 200ms
            }

            &.active, &:hover {
                button {
                    //background: linear-gradient(90deg, #4A5764 80.53%, #008037 97.87%);
                    background-color: $primary-grey;
                    color: white;
                }
            }
        }
    }

    // .vertical-line {
    //     width: 1px;
    //     height: 50vh;
    //     background: #ccc;;
    // }


    .participant-details-section{
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media(min-width: $small-screen-min-width){
            width: 60%;
        }
        
        .participant-details-section-header {
            font-size: 2rem;
            margin-bottom: 1rem;
            
        }

        .participant-details {
            width: 100%;
            margin: 1% 0;
            display: flex;
            //border: 2px solid red;
            
            label {
                font-size: 1.2rem;
                font-weight: bold;
                min-width: 45%;
            }
            
            .input-container{
                width: 50%;
                display: flex;
                flex-direction: column;
            }

            input {
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 0.5rem;
                //min-width: 80%;
                transition: all 100ms;

                
                &:focus {
                    outline: none;
                    
                    + .underline {
                        width: 100%;
                        
                    }
                }

                &:disabled {
                    background: #e9e9e9;
                    cursor: not-allowed
                }

                
            }

            .underline {
                width: 0%;
                height: 2px;
                background-color: $primary-blue;
                transition: all 150ms;
            }

            select {
                border: none;
                border-bottom: 1px solid #ccc;
                padding: 0.5rem;
                min-width: 70%;
                transition: all 100ms;
                
                &:focus {
                    outline: none;
                    + .underline {
                        width: 100%;
                    }
                }

                &:disabled {
                    background: #e9e9e9;
                    cursor: not-allowed
                }
            }

            .radio-button-container {
                width: 50%;

                input {
                    width: 5%;
                }

                label {
                    font-weight: normal;
                }
            }
        }
    }

    .actions-container {
        width: 100%;
        
        @media(min-width: $small-screen-min-width){
            width: 15%;
        }
        //border: 2px solid red;
        .actions-container-btns {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button{
                margin: 1rem;
                width: 100%;
                padding: 0.25rem 1rem;
                font-family: "Poppins Bold";   
                display: flex;
                align-items: center;
                justify-content: center;
                
                div {
                    margin-left: 0.25rem;
                }
            }
        }
    }
}