.create-account-review-details-container {
    margin-top: 2rem;

    .create-account-review-details-item {


        .create-account-label {
            font-weight: 600;
            font-size: 1.1rem;
        }
    }
}