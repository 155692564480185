@import './global.scss';

* {
  box-sizing: border-box;
}


:root {
  --primary-color: #0076c6;
  --mobile-max-width: 480px;
  --tablet-max-width: 768px;
  --small-screen-width: 1024px;
  --desktop-screen-width: 1200px;
}

body {
  margin: 0;
  
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Condensed', -apple-system;
  
  min-height: 100vh;

  background: $secondary-grey !important;
}

.backdrop {
  height: 1000vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.541);
  z-index: 1;
  position: absolute;
  top: 0; 
  left: 0;
}

.primary-purple-btn {
  background-color: $electric-indigo;

  &:hover {
    background-color: $periwinkle;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="radio"] {
  border: none;
  box-shadow: none;
  accent-color: $electric-indigo;
}


@font-face {
  font-family: 'Roboto';
  src: url('./assets/Fonts/Roboto/Roboto-Regular.ttf')
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('./assets/Fonts/RobotoCondensed/RobotoCondensed-Regular.ttf')
}

@font-face {
  font-family: 'Roboto Condensed Bold';
  src: url('./assets/Fonts/RobotoCondensed/RobotoCondensed-Bold.ttf')
}

@font-face {
  font-family: 'Cabin';
  src: url('./assets/Fonts/Cabin/Cabin-Regular.ttf')
}

@font-face {
  font-family: 'Cabin Bold';
  src: url('./assets/Fonts/Cabin/Cabin-Bold.ttf')
}

@font-face {
  font-family: 'Source Sans';
  src: url('./assets/Fonts/SourceSans/SourceSans3-Regular.ttf')
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/Fonts/Poppins/Poppins-Regular.ttf')
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('./assets/Fonts/Poppins/Poppins-Bold.ttf')
}



/* width */
// ::-webkit-scrollbar {
//   width: 20px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   //box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
  
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: $primary-grey;
//   //border-radius: 10px;
//   border: 4px solid $secondary-green
// }