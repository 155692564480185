@import '../../../global.scss';

.account-beneficiary-list-container {
    &-header {
        font-size: 1.2rem;
        font-weight: bold;
    }

    margin-bottom: 1rem;
}

.beneficiary-detail-card {
    background-color: rgba(128, 128, 128, 0.055);
    padding: 0.5rem;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;

    &-header{
        display: flex;
        justify-content: space-between;

        .delete-benificary-detail {
            &:hover{
                cursor: pointer;
            }

            i {
                color: red;
            }
        }
    }

    &-name  {
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.add-client-beneficiary-container {
    margin-top: 2rem;

    
}

.add-edit-btn-trigger {
    width: 20%;

    button{
        width: 100%;
    }
}

.add-edit-client-btn-container {
    width: 100%;
    
    button {
        width: 25%;
        margin: 1rem;
    }
}