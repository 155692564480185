@import '../../../global.scss';
.client-header-container{
    margin-left: 1rem;
}

.client-name {
    font-size: 1.2rem;
    font-weight: bold;
}

.client-header {
    font-size: 3rem;
}

.client-header-name {
    font-size: 1.5rem;
}

.actions-container-header {
    font-size: 3rem;
}

.client-details-and-select-container {
    width: 90%;
    display: flex;
    flex-direction: column;

}

.client-detail-select-list {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    li {
        padding: 0.5rem;
        margin: 1rem;
        font-size: 1.2rem;
        //font-weight: bold;
        border-top: 4px solid rgba(255, 166, 0, 0.541);
        border-left: 4px solid rgba(255, 166, 0, 0.541);
        border-right: 4px solid rgba(255, 166, 0, 0.541);
        transition: all 300ms;

        &:hover {
            cursor: pointer;
            background-color: rgba(255, 166, 0, 0.541);
        }
    }

    .active{
        background-color: rgba(255, 166, 0, 0.541);
        border-top: 3px solid rgb(132, 0, 255); 
        border-left: 3px solid rgb(132, 0, 255); 
        border-right: 3px solid rgb(132, 0, 255); 
    }
}



.client-details-container {
    border: 2px solid black;
    padding: 1rem;
    margin: 1rem;
    max-height: 40vh;
    overflow: auto;
    

    .client-details {
        display: none;
        padding: 1rem;
        flex-wrap: wrap;
        height: 100%;
        transition: all 300ms;
        

         &.active {
            display: flex;

         }

         &.display-center {
            justify-content: space-evenly;
         }

         &.display-column {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-evenly;
        }

         .client-detail {
            margin: 1rem;
            width: 15%;
            font-size: 1rem;

         }

         .client-detail-label{
            font-weight: bold;
         }
    }

    .hr {
        margin: 1rem; 
        height: 1px;
        background-color: black;
    }

    .client-details-regulatory-section {
        margin: 1rem;
        background-color: rgba(0, 0, 255, 0.212);

        p {
            font-size: 1.25rem;
            padding: 0.25rem;
        }
    }

    .client-regulatory-row {
        display: flex;
    }

    .client-details-table-container {
        margin: 0.5rem;
    }

    .client-details-table-name {
        font-size: 1.25rem;
        text-align: center;
        font-weight: bold;
    }

    .client-details-table {
        border-collapse: collapse;
        border: 2px solid black;
        
        th {
            padding: 0.5rem;
            border: 2px solid black;
            background-color: rgba(173, 70, 241, 0.719);
            color: white;
            text-align: center;
        }

        tr {
            &:hover {
                td {
                    cursor: pointer;
                    background-color: rgba(99, 99, 99, 0.315);
                }
            }
        }

        td {
            padding: 0.5rem 1rem;
            border: 2px solid black;
            transition: all 300ms;
        }
    }
}

.actions-container {
    width: 100%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .action-items-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .action-item {
        padding: 1rem;
        width: 20%;
        border-radius: 2rem;
        text-align: center;
        margin: 1rem;

        &-header {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
        }

        &-subheader {
            font-size: 1.25rem; 
            margin-bottom: 2rem;
        }

        button {
            background: none;
            border: none;
            background-color: $electric-indigo;
            padding: 0.5rem 1rem;
            border-radius: 1rem;
            color: white;
            transition: all 300ms;
            margin: 1rem;

            &:hover {
                cursor: pointer;
                color: black;
                background-color: $periwinkle;
            }
        }
    }

    .completed-action {
        //background-color: rgba(5, 243, 5, 0.705);
        box-shadow: 2px 2px 2px 2px rgba(177, 175, 175, 0.575);
    }

    .not-completed-action {
        //background-color: rgba(243, 5, 5, 0.705);
        box-shadow: 2px 2px 2px 2px rgba(177, 175, 175, 0.575);
    }
}
