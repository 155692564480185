.create-new-client-form-container{
    padding: 0.5rem;
    width: 100%;


}

.create-new-client-header {
    font-size: 1.5rem;
    text-align: center;
}

.create-new-client-form-control {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
}

.create-new-client-form-control label {
    min-width: 25%;
    font-weight: bold;
}

.create-new-client-form-control input {
    min-width: 20%;
    margin-left: 0.5rem;
}

.create-new-client-btn-control {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.participant-role-create {
    
    &-header{
        font-size: 1.2rem;
    }
}

.form-body-inputs {
    display: flex;
    justify-content: space-evenly
}

.new-participant-basic-info-header {
    font-size: 1.2rem;
}