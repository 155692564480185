@import '../../../global.scss';

.edit-client-full-container {
    padding: 1rem;
    width: 100%;
    
    .btn-save-client-details {
        text-align: center;
        width: 30%;
        margin: auto;
        margin-top: 2rem;
    }
}

.edit-client-header {
    justify-content: flex-start;
    margin-bottom: 1rem;
    border-bottom: 1px solid black;

    p {
        text-align: center;
        font-size: 2.5rem;
    } 
}

.edit-client-container {
    display: flex;
    width: 1005;

}

.edit-client-progress {
    width: 20%;
    min-height: 70vh;

    .edit-client-progress-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        transition: all 200ms;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: bold;
        margin: 1rem;

        p {
            text-align: center;
            min-width: 200px;
            font-size: 1.25rem;
            margin: 0.5rem;
        }

        &:hover {
            .edit-client-progress-item-active-line {
                width: 70%;
            }
        }

        &.active {

            .edit-client-progress-item-active-line {
                width: 70%;
            }
        }

        &-active-line {
            width: 0%;
            height: 4px;
            background-color: $electric-indigo;
            transition: all 200ms;
        }
    }
}

.edit-client-wizard {
    width: 80%;

    .edit-client-wizard-item{
        margin: 0.5rem;
        display: none;


        &-header {
            font-size: 2rem;
            margin-bottom: 1rem;
            display: flex;

            .special-message {
                font-size: 1rem;
            }
            
            .edit-client-wizard-item-back {
                //padding: 0.25rem 2rem;
                background-color: white;
                border: none;
                
                border-radius: 1rem;

                i {
                    font-size: 1.5rem;
                    color: $electric-indigo;
                    transition: 200ms;
                }

                &:hover {
                    i {
                        color: $electric-indigo-with-opacity;
                    }
                }
            }
        }
        
        &-sub-header{
            margin: 0.5rem;
        }

        &-body {
            

        }

        &-input-control{
            margin: 1rem;
            display: flex;
            align-items: center;
            
            label {
                font-size: 1rem;
                font-weight: bold;
                min-width: 300px;
            }

            select {
                margin-left: 0.5rem;
                min-width: 25%;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                border: none;
                box-shadow: 1px 0.5px 5px 0px rgba(0, 0, 0, 0.637);
            }

            input {
                margin-left: 0.5rem;
                min-width: 25%;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                border: none;
                box-shadow: 1px 0.5px 5px 0px rgba(0, 0, 0, 0.637);
            }

            input[type="radio"] {
                border: none;
                box-shadow: none;
                accent-color: $electric-indigo;
            }

            input[type="date"] {
                margin-left: 0.5rem;
                min-width: 25%;
                padding-left: 0.5rem;
            }

            i {
                margin-left: 0.5rem;
            }

            &-radio {
                
                min-width: 10%;

                label {
                    width: 10%;
                    font-size: 1rem;
                    min-width: 10px;
                }
            }
        }

        &.active {
            display: flex;
            flex-direction: column;
        }

        .edit-client-net-worth-asset-liability-container {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;

            @media screen and (min-width: $desktop-screen-width) {
                flex-direction: row;
                align-items: flex-start;
            }

            .edit-client-net-worth-asset-liability {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: center;

                @media screen and (min-width: $desktop-screen-width) {
                    width: 33%;
                }

                &-button-add {
                    width: 80%;
                    margin-left: 1.25rem;
                    padding: 0.5rem 1rem;
                    background-color: $electric-indigo;
                    color: white;
                    border-radius: 1rem;
                    border: 1px solid white;
                    transition: all 200ms;

                    @media screen and (min-width: $desktop-screen-width) {
                        width: 40%;
                    }

                    &:hover {
                        background-color: $electric-indigo-with-opacity;
                        color: white;
                    }
                }
            }
        }

        .edit-client-list-item-container {
            background-color: rgba(165, 96, 255, 0.062);
            margin: 1rem;
            padding: 0.5rem;
            border-radius: 1rem;
            box-shadow: 4px 0.5px 4px 4px rgba(10, 10, 10, 0.199);

            @media screen and (min-width: $desktop-screen-width) {
                margin: 1.25rem;
            }

            &-header {
                display: flex;
                justify-content: space-between;
                margin: 0.25rem;
                font-size: 1.2rem;
                font-weight: bold;
                text-decoration: underline;
                padding: 0;

                .remove-item-btn {
                    background-color: transparent;
                    border: none;
                    i {
                        font-size: 2rem;
                        color: red;
                        transition: color 200ms;
                    }

                    &:hover {
                        i {
                            color: rgba(255, 0, 0, 0.411)
                        }
                    }
                }
            }

            .container-row{
                flex-wrap: wrap;
                display: flex;
    
                .edit-client-wizard-item-input-control {
                    label {
                        font-size: 1.1rem;
                        font-weight: bold;
                        min-width: 100px;
                    }
                }
            }
        }

        
    }

    
}

.btn-nice-purple{
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: $electric-indigo;
    color: white;
    transition: all 200ms;
    border: none;


    &:hover {
        background-color: $electric-indigo-with-opacity;
        color: white;
    }
}