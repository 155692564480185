@import '../../../global.scss';

.create-new-bank-account-container {
    padding: 0.5rem;
    margin-top: 1rem;

    &-header{
        font-size: 1.5rem;
    }

    .btn-actions-container{
        width: 50%;
        text-align: center;
        button {
            
            width: 25%;
            margin: 1rem;
        }
    }
}

.bank-account-details-list {
    padding: 0.25rem;
}

.bank-account-detail-item {
    background-color: rgba(128, 128, 128, 0.055);
    border-radius: 0.5rem;  
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    margin-bottom: 1rem;
    font-family: 'Roboto', sans-serif;
}

.bank-account-detail-item-account-name {
    font-size: 1.2rem;
    font-weight: bold;

    .fav-account-icon{
        margin-left: 1rem;
        color: $old-gold;
    }
}

.btn-cancel-container {
    margin-left: 1rem;
}