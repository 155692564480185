.add-transfer-detail-form {
    margin-top: 1rem;
    
    &-header {
    
        font-size: 1.2rem;
    }
}

.transfer-detail-card{
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 2px solid black;

    &-header {
        display: flex;
        justify-content: space-between;

        i {
            font-size: 1.5rem;
            
            &:hover {
                cursor: pointer;
            }
        }
        
    }
}